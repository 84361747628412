import headerImg from "../assets/img/header-img.webp";
import isoLogo from "../assets/img/iso-27001-logo.webp";
import socLogo from "../assets/img/soc2-logo.webp";

export const Banner = () => {
  return (
    <section id="hero" class="hero d-flex align-items-center mt-4">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 d-flex flex-column justify-content-center">
            <h1 data-aos="fade-up" className="fs-1">Fintech Solutions Delivered<br/> Faster, Better, Cost-Efficient </h1>
            <p data-aos="fade-up">We are a center of excellence for all your technology needs, whether you are an established financial institution, or an entrepreneur who is looking to disrupt the fintech industry with your products and services. We build, run and manage resilient and secure financial systems, faster and cheaper.</p>
            <p data-aos="fade-up">
              <b>Contact us today to discuss your fintech needs.</b>
            </p>
            <div data-aos="fade-up">
              <div class="text-center text-lg-start">
                <a href="/#contact" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center mt-3 mb-2">
                  <span>Get in Touch</span>
                </a>
              </div>
            </div>
            {/* Certification branding section */}
            <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start mt-4" data-aos="fade-up">
              <div className="d-flex align-items-center me-md-4">
                <img src={isoLogo} alt="ISO 27001:2022 Certification" height="60" className="me-2" />
                <span className="fs-6 fw-normal d-none d-md-inline">ISO 27001:2022 Certified</span>
              </div>
              <div className="d-flex align-items-center">
                <img src={socLogo} alt="SOC 2 Certification" height="60" className="me-2" />
                <span className="fs-6 fw-normal d-none d-md-inline">SOC2 Certified</span>
              </div>
            </div>
          </div>
          <div class="col-lg-6 hero-img" data-aos="zoom-in">
            <img src={headerImg} class="img-fluid animated" alt="Banner" />
          </div>
        </div>
      </div>
    </section>
  );
};
