import React from 'react';

const LinkedInPostCard = ({ embedCode }) => {
    return (
      <div className="post-card">
        <div className="iframe-wrapper">
          <div className="linkedin-embed " dangerouslySetInnerHTML={{ __html: embedCode }} />
        </div>
      </div>
    );
  };
  

export const RecentPosts = () => {
  const linkedInPosts = [
    {
      embedCode: '<iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7308177456958439425?collapsed=1" frameborder="0" allowfullscreen="" title="Embedded post" style="overflow:hidden;"></iframe>'
    },
    {
      embedCode: '<iframe src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7305949861512704001?collapsed=1" frameborder="0" allowfullscreen="" title="Embedded post" overflow: hidden;></iframe>'
    },
    {
      embedCode: '<iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7303841053583843328?collapsed=1" frameborder="0" allowfullscreen="" title="Embedded post" overflow: hidden;></iframe>'
    },
  ];

  return (
    <section id="recent-posts" className="recent-posts-section">
      <div className="container">
        <header className="section-header text-center">
          <h2>Our Recent Posts</h2>
        </header>
        
        <div className="posts-grid">
          {linkedInPosts.map((post, index) => (
            <LinkedInPostCard 
              key={index}
              embedCode={post.embedCode}
            />
          ))}
        </div>
        
        <div class="text-center" data-aos="fade-up">
        <a href="https://www.linkedin.com/company/fintechglobalcenter/posts" target="_blank" rel="noopener noreferrer" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center mt-5">
          <span>View all Posts</span>
        </a>
      </div>
      </div>
    </section>
  );
};

export default RecentPosts;